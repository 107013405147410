import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import { PortableText } from "@portabletext/react";
import SanityImage from "gatsby-plugin-sanity-image";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { graphql, Link } from "gatsby";
import * as styles from "../../pages/showcase/Sectors.module.scss";
import Typed from "react-typed";

const SectorPageTemplate = ({ pageContext, data }) => {
  const { sectorData, sectors } = pageContext;
  const heroImage = data.heroImage.childImageSharp.original.src;

  const sections = [...sectorData.sections];
  console.log(sectors);
  const sectorsSorted = sectors.sort((a, b) => {
    if (a.order !== null && b.order === null) {
      return -1;
    }
    if (b.order !== null && a.order === null) {
      return 1;
    }
    if (a.order !== b.order) {
      return a.order - b.order;
    }
    return a.title.localeCompare(b.title);
  });
  const currentSectorIndex = sectorsSorted.findIndex(
    (sector) => sector.handle.current === sectorData.handle.current
  );
  return (
    <>
      <SEO title="Sectors" />
      <Layout>
        <div
          className={`height-650 bg_image ${styles.heroBg}`}
          style={{
            backgroundImage: `url(${heroImage})`,
          }}
        >
          <div className="container" style={{ zIndex: 8 }}>
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-7">
                <div className="inner text-start">
                  <h1 className="title">
                    Light up <br />
                    <Typed
                      strings={[
                        sectorsSorted[currentSectorIndex],
                        ...sectorsSorted.filter(
                          (sector) =>
                            sector.handle !==
                            sectorsSorted[currentSectorIndex].handle
                        ),
                      ].map(({ title }) => title.replace("&", "&amp;"))}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  {/*<p className="description">*/}
                  {/*  HueBox: bringing [sector] to life with dynamic animations*/}
                  {/*</p>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content mb--30 mt--30">
          <div class="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs defaultIndex={currentSectorIndex} onSelect={() => false}>
                  <div className="row row--30 align-items-center">
                    <div className="col-lg-12">
                      <div className="rn-default-tab style-three">
                        <div className="tab-button-panel">
                          <TabList className="tab-button">
                            {sectors.map((sector) => (
                              <Tab
                                key={sector.handle.current}
                                selected={
                                  sector.handle.current ===
                                  sectorData.handle.current
                                }
                              >
                                <div className="rn-tab-button">
                                  <Link
                                    to={`/sectors/${sector.handle.current}`}
                                  >
                                    {sector.title}
                                  </Link>
                                </div>
                              </Tab>
                            ))}
                          </TabList>
                        </div>

                        <div className="tab-content-panel">
                          {sectorsSorted.map((sector) => (
                            <TabPanel key={sector.handle.current}>
                              <div className="rn-tab-content">
                                {sector.handle.current ===
                                  sectorData.handle.current && (
                                  <div className="row align-items-center row--30">
                                    <div className="col-lg-4">
                                      <div className="section-title">
                                        <h4 className="title">
                                          {sector.introTitle}
                                        </h4>
                                        <PortableText
                                          value={sector._rawIntro}
                                        ></PortableText>
                                        {/*<div className="read-more">*/}
                                        {/*  <a*/}
                                        {/*    className="btn-default btn-small"*/}
                                        {/*    href="src/doob/elements/tab/TabThree#"*/}
                                        {/*  >*/}
                                        {/*    Read More*/}
                                        {/*  </a>*/}
                                        {/*</div>*/}
                                      </div>
                                    </div>
                                    <div className="col-lg-8 mt_md--30 mt_sm--30">
                                      <div className="row row--15 mt_dec--30 service-wrapper">
                                        {sections.map((section, idx) => {
                                          const { caption, ...posterData } =
                                            section.poster ?? {};
                                          return (
                                            <div
                                              className="col-lg-6 col-md-6 col-sm-6 col-12"
                                              key={idx}
                                            >
                                              <div className="card-box card-style-1 text-start">
                                                <div className="inner">
                                                  <div className="image">
                                                    <Link to="#service">
                                                      <SanityImage
                                                        {...posterData}
                                                        alt={caption}
                                                        className="w-100 radius"
                                                        width="1280"
                                                        height="800"
                                                      />
                                                    </Link>
                                                  </div>
                                                  <div className="content">
                                                    <h4 className="title mb--20">
                                                      {section.title}
                                                    </h4>
                                                    <PortableText
                                                      value={
                                                        section._rawContent
                                                      }
                                                    ></PortableText>
                                                    {/*<Link*/}
                                                    {/*  className="btn-default btn-small"*/}
                                                    {/*  to="#service"*/}
                                                    {/*>*/}
                                                    {/*  Read More*/}
                                                    {/*</Link>*/}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </TabPanel>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SectorPageTemplate;
export const query = graphql`
  {
    heroImage: file(
      relativePath: {
        eq: "Hue_Box_In_Scene_Woman_Graphic_NICE_Lower_More_Reflection_CHAIR_For_Scale.jpg"
      }
    ) {
      childImageSharp {
        original {
          src
        }
        #        gatsbyImageData(
        #          width: 1920
        #          quality: 80
        #          placeholder: NONE
        #          layout: CONSTRAINED
        #        )
      }
    }
  }
`;
